/* ==========================================================================
   Helper classes
   ========================================================================== */

.clearfix {
  overflow: auto;

  &:after {
    content: "";
    clear: both;
    display: table;
  }
}
