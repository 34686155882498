/* ==========================================================================
   Button elements
   ========================================================================== */

a.btn,
a.btn:not([href]):not([tabindex]),
button,
button.btn,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  align-self: center;
  background-color: $c-white;
  border: 1px solid $c-black;
  border-radius: 0px;
  color: $c-primary;
  display: inline-block;
  font-family: $ff-primary;
  font-size: $global-font-size;
  font-weight: $fw-bold;
  line-height: 1.9em;
  padding: 0px 18px;
  text-transform: none;
  transition: $animate-transition;

  &:focus,
  &:hover {
    background-color: $c-grey-light;
    text-decoration: none;
  }

  &:disabled {
    background-color: $c-grey-light;
    border: 1px solid $c-grey-light;
    color: $c-grey;
  }
}

.btn.btn-link,
a.btn.btn-link,
a.btn.btn-link:not([href]):not([tabindex]) {
  background: transparent;
  border: 0;
  color: $c-primary;
  font-weight: $fw-normal;
  margin: 0;
  padding: 0;
  text-transform: none;

  &:focus,
  &:hover {
    background: transparent;
    color: $c-primary;
    text-decoration: underline;
  }

  &:disabled {
    background-color: transparent;
    border: 0;
    color: $c-grey;
  }
}

.btn.btn-primary,
a.btn.btn-primary,
a.btn.btn-primary:not([href]):not([tabindex]) {
  background-color: $c-secondary;
  border: 0;
  border-bottom: 5px solid $c-secondary-dark;
  color: $c-white;

  &:focus,
  &:hover {
    background-color: $c-secondary-dark;
    color: $c-white;
  }

  &:disabled {
    background-color: $c-grey-light;
    border: 1px solid $c-grey-light;
    color: $c-grey;
  }
}
