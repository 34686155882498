/* ==========================================================================
   Typographic elements
   ========================================================================== */

a:not(.btn),
a:not(.btn):visited,
a:not([href]):not([tabindex]),
.link {
  color: $c-primary;
  cursor: pointer;

  &:hover {
    color: $c-primary;
    text-decoration: underline;
  }
}

h1,
h2,
h3 {
  text-transform: uppercase;
}

.text-primary {
  color: $c-primary;
  font-weight: $fw-bold;
}
