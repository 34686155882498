/* ==========================================================================
   Sitewide Variables
   ========================================================================== */

/* Style Specific Variables
    ========================================================================== */

/* COLORS */
$c-primary: #1f285c;
$c-secondary: #e65525;
$c-secondary-dark: #a33916;
$c-grey-light: #e6e6e6;
$c-grey: #666;
$c-black: #000;
$c-white: #fff;

/* FONTS */
$ff-primary: "Open Sans Condensed", Verdana, Geneva, sans-serif;
$ff-secondary: "Open Sans", Verdana, Geneva, sans-serif;
$fw-light: 300;
$fw-normal: 400;
$fw-bold: 700;

/* Grid Specific Variables
   ========================================================================== */

$bp-small-mobile-max: 479px;
$bp-mobile-min: 480px;
$bp-mobile-max: 767px;
$bp-tablet-min: 768px;
$bp-tablet-max: 1024px;
$bp-small-desktop-min: 1025px;
$bp-small-desktop-max: 1279px;
$bp-desktop-min: 1280px;

/* Size Specific Variables
   ========================================================================== */

/**
 * BASE SIZING
 *
 * This default font size determines the relative size of special content in components and should be derived from
 * the default paragraph font size
 */
$global-font-size: 16px;
$global-line-height: 1.75; /* 28 */
$global-scale-factor: 1.125; /* 8:9 - Major Second */

/* These are set to rem so it is relative to the global font size that changes depending on breakpoint */
$global-spacing: (20 / ($global-font-size/1px)) + 0rem;
$global-spacing-double: ($global-spacing * 2);
$global-spacing-half: ($global-spacing / 2);

$global-font-size-mobile: 16px;
$global-line-height-mobile: 1.75; /* 28 */
$global-scale-factor-mobile: 1.125; /* 8:9 - Major Second */

/* Animation Variables
   ========================================================================== */
$animate-duration: 0.3s;
$animate-transition: all 0.3s ease;

/*========================== Custom Theme related Variables ==========================*/

$cpq_page_header_color: #333333;
$cpq_black: #000000;
$cpq_anchor_color: #071f60;
$cpq_desc_color: #494949;
$cpq_color_assent: #e65526;
$cpq_color_white: #ffffff;
